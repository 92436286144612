import './activity-log.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import dayjs from 'dayjs';

function PmaActivityLog() {
  const {t} = useTranslation();
  const {hacId} = useParams();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  // Estados para manejar los datos de la API y los errores
  const [notifications, setNotifications] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Llamada a la API cuando se carga el componente
  useEffect(() => {
    if (hacId) {
      const fetchNotifications = async () => {
        const url = `${process.env.REACT_APP_API_URL}/hac/notification?hacId=${hacId}`;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          },
        };

        try {
          const response = await apiClient.get(url, config);
          if (response.status === 200 || response.status === 204) {
            setNotifications(response.data || null); // Guardamos las notificaciones
            setDataLoaded(true);
          }
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      fetchNotifications();
    }
  }, [hacId, token, t]);

  return (
    <>
      {token &&
      (role === 'pma' || role === 'pmaGM') ? (
        <div className="App">
          <Header />
          <main>
            <section className="activity-log">
              <div className="inner">
                <Link to="/pma/main/" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')} / {breadcrumbName}
                </Link>
                <h2>
                  {t('activity-log-title')}
                </h2>
                <p>{t('activity-log-text')}</p>

                {dataLoaded ? (
                  notifications ? (
                    <div>
                      {notifications.map((notification) => {
                        const formattedDate = dayjs(notification.timestamp).format('DD/MM/YYYY');
                        const formattedTime = dayjs(notification.timestamp).format('HH:mm');

                        return (
                          <div key={notification.id} className="notification-item">
                            <header>
                              <span className="text">
                                {t(`activity-log-${notification.section}-${notification.operation}`)}
                              </span>
                              <span className="date">
                                {formattedDate} - {formattedTime}
                              </span>
                            </header>
                            <p>{notification.actor_name} - {t(`common-role-${notification.actor_role}`)}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <strong>{t('activity-log-empty')}</strong>
                  )
                ) : (
                  <p>{t('common-loading')}</p>
                )}
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default PmaActivityLog;
