import React, { useEffect, useState } from 'react';
import { Alert, Collapse, Snackbar } from '@mui/material';
import './settings.scss';
import Header from '../../../organisms/header/header.js';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { jwtDecode } from 'jwt-decode';
import { Link, Navigate } from 'react-router-dom';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import decodeToken from "../../../../utils/decode-token";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function RetailerSettings() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  const role = decodeToken(token).role;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setFocus,
    formState: { errors },
  } = useForm();

  const [userLanguage, setUserLanguage] = useState('');
  const [languages, setLanguages] = useState([]);  // Estado para almacenar los lenguajes
  const [areaCode, setAreaCode] = useState('');    // Almacenar el área para solicitar idiomas

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Obtener datos del usuario y código de área
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token') || null;

        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${userId}`;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await apiClient.get(url, config);

        const { name, last_name, prefix_phone, phone, preferred_language, area_code } = response.data;

        setValue('name', name);
        setValue('lastName', last_name);
        setValue('prefixPhone', prefix_phone);
        setValue('phone', phone);
        setUserLanguage(preferred_language);
        setAreaCode(area_code);  // Establecer el código de área para obtener idiomas

      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };

    fetchData();
  }, [setValue, userId, t]);

  // Llamada a la API para obtener lenguajes basados en `areaCode`
  useEffect(() => {
    if (areaCode) {  // Verificar si se ha definido el código de área
      const fetchLanguages = async () => {
        try {
          const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/languages?area_code=${areaCode}`);
          if (response.status === 200) {
            // Mapeamos los lenguajes recibidos al formato { name: t('languages-es'), code: 'es' }
            const languagesData = response.data.map((langData) => (
              langData.language.map((code) => ({
                name: t(`languages-${code}`),  // Traducción usando la función de i18n
                code: code
              }))
            )).flat();  // Aplanamos los arrays anidados
            setLanguages(languagesData);
          }
        } catch (error) {
          console.error('Error fetching languages:', error);
        }
      };
      fetchLanguages();
    }
  }, [areaCode, t]);

  const [expandedSections, setExpandedSections] = useState({
    language: false,
    contact: false,
    password: false,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const [openSnackbarLanguages, setOpenSnackbarLanguages] = useState(false);
  const [openSnackbarContact, setOpenSnackbarContact] = useState(false);

  const handleCloseSnackbarLanguages = () => {
    setOpenSnackbarLanguages(false);
  };
  const handleCloseSnackbarContact = () => {
    setOpenSnackbarContact(false);
  };

  const saveLanguage = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${userId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const data = {
        language: userLanguage,
      };

      await apiClient.patch(url, data, config);
      i18n.changeLanguage(userLanguage);
      sessionStorage.removeItem('loggedUser');
      setSnackbarMessage(t('settings-setting1-feedback'));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting1-error'));
    }

    setOpenSnackbarLanguages(true);
  };

  const saveContactInfo = async (data) => {
    try {

      const phoneNumber = parsePhoneNumberFromString(
        data.prefixPhone + data.phone
      );

      if (!phoneNumber || !phoneNumber.isValid()) {
        setSnackbarMessage(t('alert-phone-format'));
        setFocus('phone');
        setOpenSnackbarContact(true);
        return;
      }

      const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${userId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { name, lastName, prefixPhone, phone } = data;

      await apiClient.patch(
        url,
        { name, last_name: lastName, prefix_phone: prefixPhone, phone },
        config
      );
      sessionStorage.removeItem('loggedUser');
      setSnackbarMessage(t('settings-setting2-feedback'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting2-error'));
    }

    setOpenSnackbarContact(true);
  };

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
          <div className="App">
            <Header />
            <main>
              <section className="settings">
                <div className="inner">
                  <Link to="/retailer/main/" className="back-button">
                    <span className="icon-chevron-left"></span>{' '}
                    {t('common-back')}
                  </Link>
                  <h2>{t('settings-title')}</h2>
                  <p>{t('settings-text')}</p>

                  {/* contact */}
                  <div className="title" onClick={() => toggleSection('contact')}>
                    <h5>{t('settings-setting2')}</h5>
                    <button className="btn text">
                      {expandedSections.contact ? (
                        <span className="icon-minus"></span>
                      ) : (
                        <span className="icon-plus"></span>
                      )}
                    </button>
                  </div>
                  <Collapse in={expandedSections.contact}>
                    <form onSubmit={handleSubmit(saveContactInfo)}>
                      <Grid container spacing={1}>
                        <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                          <div
                            className={`form-control required ${
                              errors.name ? 'error' : ''
                            }`}
                          >
                            <label htmlFor="name">{t('common-first-name')}</label>
                            <input
                              maxLength="50"
                              type="text"
                              id="name"
                              name="name"
                              autoComplete="off"
                              {...register('name', {
                                required: t('alert-name'),
                              })}
                            />
                            {errors.name && <span>{errors.name.message}</span>}
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                          <div
                            className={`form-control required ${
                              errors.lastName ? 'error' : ''
                            }`}
                          >
                            <label htmlFor="lastName">{t('common-last-name')}</label>
                            <input
                              maxLength="50"
                              type="text"
                              id="lastName"
                              name="lastName"
                              autoComplete="off"
                              {...register('lastName', {
                                required: t('alert-last-name'),
                              })}
                            />
                            {errors.lastName && (
                              <span>{errors.lastName.message}</span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div
                        className={`form-control ${
                          errors.prefixPhone ? 'error' : ''
                        }`}
                      >
                        <Grid container spacing={1}>
                          <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                            <div
                              className={`form-control required ${
                                errors.prefixPhone ? 'error' : ''
                              }`}
                            >
                              <label htmlFor="prefix_phone">
                                {t('common-prefix')}
                              </label>
                              <input
                                type="text"
                                id="prefix_phone"
                                name="prefixPhone"
                                autoComplete="off"
                                placeholder="+xx/+xxx"
                                {...register('prefixPhone', {
                                  required: t('alert-prefix'),
                                  pattern: {
                                    value: /^\+[0-9]{2,3}$/,
                                    message: '+xx/+xxx',
                                  },
                                })}
                              />
                              {errors.prefixPhone && (
                                <span>{errors.prefixPhone.message}</span>
                              )}
                            </div>
                          </Grid>
                          <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                            <div
                              className={`form-control required ${
                                errors.phone ? 'error' : ''
                              }`}
                            >
                              <label htmlFor="phone">{t('common-phone')}</label>
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                autoComplete="off"
                                {...register('phone', {
                                  required: t('alert-phone'),
                                  validate: (value) => {
                                    const phoneNumber = parsePhoneNumberFromString(
                                      `${watch('prefixPhone')}${value}`
                                    );
                                    return (
                                      phoneNumber && phoneNumber.isValid()
                                        ? true
                                        : t('alert-phone-format')
                                    );
                                  },
                                })}
                              />
                              {errors.phone && <span>{errors.phone.message}</span>}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="actions">
                        <button className="btn" type="submit">
                          {t('common-save-changes')}
                        </button>
                      </div>
                    </form>
                  </Collapse>

                  {/* language */}
                  <div className="title" onClick={() => toggleSection('language')}>
                    <h5>{t('settings-setting1')}</h5>
                    <button className="btn text">
                      {expandedSections.language ? (
                        <span className="icon-minus"></span>
                      ) : (
                        <span className="icon-plus"></span>
                      )}
                    </button>
                  </div>
                  <Collapse in={expandedSections.language}>
                    <div className="form-control">
                      <select
                        name="area_code"
                        id="country"
                        className="normal"
                        value={userLanguage}
                        onChange={(e) => setUserLanguage(e.target.value)}
                      >
                        {languages.map((language) => (
                          <option key={language.code} value={language.code}>
                            {language.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="actions">
                      <button className="btn" onClick={saveLanguage}>
                        {t('common-save-changes')}
                      </button>
                    </div>
                  </Collapse>

                  {/* resetear password */}
                  <div className="title" onClick={() => toggleSection('password')}>
                    <h5>{t('settings-setting4')}</h5>
                    <button className="btn text">
                      {expandedSections.password ? (
                        <span className="icon-minus"></span>
                      ) : (
                        <span className="icon-plus"></span>
                      )}
                    </button>
                  </div>
                  <Collapse in={expandedSections.password}>
                    <p>{t('settings-setting4-text')}</p>
                    <div className="actions">
                      <Link to="/password-recovery" className="btn">
                        {t('common-change-password')}
                      </Link>
                    </div>
                  </Collapse>
                </div>
              </section>
            </main>
            <Snackbar
              open={openSnackbarLanguages}
              autoHideDuration={3000}
              onClose={handleCloseSnackbarLanguages}
            >
              <Alert severity="success" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={openSnackbarContact}
              autoHideDuration={3000}
              onClose={handleCloseSnackbarContact}
            >
              <Alert severity="success" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <Navigate to="/login" />
        )}
    </>
  );
}

export default RetailerSettings;
