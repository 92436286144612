import './edit-saleperson.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';
import Grid from "@mui/material/Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import { useForm } from 'react-hook-form';
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

function EditSaleperson() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const { retailerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const salepersonId = location.state?.user.id || ''; // Email pasado desde el estado
  const [saleperson, setSaleperson] = useState({});
  const [areaCode, setAreaCode] = useState(location.state?.areaCode || '');
  const [retailerName, setRetailerName] = useState('');

  const [languages, setLanguages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const { breadcrumbName } = location.state || {};

  // Obtener datos del Retailer GM por correo
  useEffect(() => {
    const fetchSaleperson = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${salepersonId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          },
        };
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setSaleperson(response.data); // Almacenar la respuesta en Saleperson
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };

    if (salepersonId) {
      fetchSaleperson();
    }
  }, [salepersonId, token, t]);

  // Llamada para obtener el área_code del retailer
  useEffect(() => {
    const fetchRetailerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/retailer?retailerId=${retailerId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200 && response.data.retailer.area_code) {
          setAreaCode(response.data.retailer.area_code);
          setRetailerName(response.data.retailer.name);
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };

    fetchRetailerData();
  }, [retailerId, token, t]);


  const schema =  Joi.object({
    name: Joi.string().required().messages({ 'string.empty': t('alert-name') }),
    last_name: Joi.string().required().messages({ 'string.empty': t('alert-last-name') }),
    email: Joi.string().email({ tlds: { allow: false } }).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email'),
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2,3}$/).required().messages({
      'string.pattern.base': t('alert-prefix'),
      'string.empty': t('alert-prefix'),
    }),
    phone: Joi.string().pattern(/^[0-9]{9}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone'),
    }),
    preferred_language: Joi.string().required().messages({ 'string.empty': t('alert-language') }),
  })

  // Uso de react-hook-form y Joi para manejar y validar el formulario
  const { register, handleSubmit, formState, setValue} = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur'
  });
  const { errors } = formState;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Cargar datos iniciales
  useEffect(() => {
    if (saleperson) {
      setValue('name', saleperson.name || '');
      setValue('email', saleperson.email || '');
      setValue('last_name', saleperson.last_name || '');
      setValue('prefix_phone', saleperson.prefix_phone || '');
      setValue('phone', saleperson.phone || '');
      setValue('preferred_language', saleperson.preferred_language || '');

      const fetchLanguages = async () => {
        try {
          const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
          const country = response.data.find((country) => country.area_code === areaCode);
          if (country && country.language) {
            const formattedLanguages = country.language.map((lang) => ({
              code: lang,
              name: t(`languages-${lang}`), // Traducir el idioma
            }));
            // Asegúrate de que el idioma preferido esté en las opciones
            if (
              saleperson.preferred_language &&
              !formattedLanguages.some((lang) => lang.code === saleperson.preferred_language)
            ) {
              formattedLanguages.unshift({
                code: saleperson.preferred_language,
                name: t(`languages-${saleperson.preferred_language}`),
              });
            }
            setLanguages(formattedLanguages);
          } else {
            setLanguages([]); // Asegúrate de manejar casos donde no haya idiomas
          }
        } catch (error) {
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'));
        }
      };
      if (areaCode) fetchLanguages();
    }
  }, [saleperson, areaCode, setValue, t]);



  const onSubmit = async (data) => {

    let payload = {
      name: data.name,
      last_name: data.last_name,
      prefix_phone: data.prefix_phone,
      phone: data.phone,
      language: data.preferred_language
    };
    try {
      const response = await apiClient.patch(
        `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${salepersonId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        setSnackbarMessage(t('alert-data-saved'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate(-1), 2000); // Regresa a la página anterior
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbarMessage(t('common-internal-server-error'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="back-button">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="back-button"
                    >
                      <span className="icon-chevron-left"></span>{' '}{t('common-back')} / {breadcrumbName}
                    </Link>
                    {' '} / {retailerName}
                  </div>
                  <h2>{t('admin-edit-saleperson-title')}</h2>

                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="name"
                          label={t('common-name')}
                          {...register('name')}
                          error={errors?.name}
                        />
                      </div>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="last_name"
                          label={t('common-last-name')}
                          {...register('last_name')}
                          error={errors?.last_name}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="email"
                          readOnly
                          label={t('common-email')}
                          {...register('email')}
                          disabled // El correo no debe ser editable
                        />
                      </div>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                      <div>
                        <Grid container spacing={1}>
                          <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="prefix_phone"
                                label={t('common-prefix')}
                                placeholder="+xx/+xxx"
                                {...register('prefix_phone')}
                                error={errors?.prefix_phone}
                              />
                            </div>
                          </Grid>
                          <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="phone"
                                label={t('common-phone')}
                                {...register('phone')}
                                error={errors?.phone}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 1, md: 6 }}>
                      <div className="form-control required">
                        <label htmlFor="preferred_language">{t('common-select-language')}</label>
                        <select
                          name="preferred_language"
                          id="preferred_language"
                          className="normal"
                          value={saleperson?.preferred_language || ''}
                          {...register('preferred_language')}
                        >
                          <option value="">{t('common-select-language')}</option>
                          {languages.map(({code, name}) => (
                            <option key={code} value={code}>
                              {name}
                            </option>
                          ))}
                        </select>
                        {errors.preferred_language && (
                          <span className="input__error">{errors.preferred_language.message}</span>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                      <button className="btn">
                        {t('common-save')}
                      </button>
                    </Grid>
                  </Grid>
                </form>

              </div>
            </section>
          </main>
          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default EditSaleperson;
