import './vehicles.scss';
import Header from '../../../organisms/header/header.js'
import React, {useEffect, useState} from "react";
import {Link, Navigate, useLocation} from "react-router-dom";
import HacVehicles from "../../../organisms/role-pma/hac-vehicles/hac-vehicles";
import {useTranslation} from "react-i18next";
import decodeToken from "../../../../utils/decode-token";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

function PmaVehicles() {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || '';
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (location.state?.openSnackbar) {
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {token &&
      (role === "pma" || role === 'pmaGM') ? (
          <div className="App">
            <Header/>
            <main>
              <section className="vehicles">
                <div className="inner">
                  <Link to="/pma/main" className="back-button">
                    <span className="icon-chevron-left"></span> {' '}
                    {t('common-back')} / {breadcrumbName}
                  </Link>
                  <h4>{t('hac-my-profile-vehicles-title')}</h4>
                  <p>{t('hac-my-profile-vehicles-text')}</p>
                  <HacVehicles breadcrumbName={breadcrumbName}/>
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{width: '100%'}}>
                      {t('alert-data-saved')}
                    </Alert>
                  </Snackbar>
                </div>
              </section>
            </main>
          </div>

        ) : (
          <Navigate to="/login" />
        )
      }
    </>

  )
}

export default PmaVehicles;